import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React, { useEffect, useState } from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import SEORevamp from "../components/common/SEO_Revamp"
import BlogModal from "../components/common/new-blog/BlogModal"
import Creative from "../components/new-blog-page/Creative"
import InvoBlogs from "../components/new-blog-page/InvoBlogs"
import * as styles from "../components/saas-files/search.module.scss"
import MainLayout from "../layouts/MainLayout"

const BlogTemplate = ({ pageContext, location }) => {
  // const [postsData, setPostsData] = useState([])
  const [topicSearch, setTopicSearch] = useState("")
  const [searching, setSearching] = useState(false)
  const [filterCatagory, setFilterCatagory] = useState({})
  const [categories, setCategories] = useState([])
  // const [loading, setLoading] = useState(false)
  const [filterData, setFilterData] = useState([])

  const [list, setList] = useState([])
  const [loadMore, setLoadMore] = useState(false)
  const [hasMore, setHasMore] = useState(false)
  const length = 9

  useEffect(() => {
    if (location.search) {
      let params = new URL(location.href)?.searchParams
      var topic = params?.get("topic")
      var category = params?.get("categories")
      topic && setTopicSearch(topic)
      category && setCategories(category?.split(","))
    }
  }, [location, searching])

  useEffect(() => {
    const filteredTopic = pageContext?.allPosts?.filter(x => {
      if (topicSearch) {
        return x?.title?.toLowerCase()?.includes(topicSearch?.toLowerCase())
      } else if (topicSearch && categories?.length > 0) {
        return (
          x?.title?.toLowerCase().includes(topicSearch?.toLowerCase()) &&
          x?.categories
            .map(cat => cat?.slug)
            .some(item => categories?.includes(item))
        )
      } else if (categories?.length > 0) {
        return x?.categories
          .map(cat => cat?.slug)
          .some(item => categories?.includes(item))
      }
    })
    setFilterData(filteredTopic)
    setList([...filteredTopic?.slice(0, length)])
  }, [topicSearch, categories, location])

  useEffect(() => {
    let catagoryModel =
      filterCatagory?.selectedCategories &&
      Object.keys(filterCatagory?.selectedCategories).length > 0 &&
      Object.keys(filterCatagory?.selectedCategories)

    searching &&
      navigate(
        `/search/?${
          filterCatagory?.search ? `topic=${filterCatagory?.search}` : ""
        }${topicSearch ? `topic=${topicSearch}` : ""}${
          filterCatagory?.search && catagoryModel ? "&" : ""
        }${catagoryModel ? `categories=${catagoryModel}` : ""}`
      )
    setSearching(false)
  }, [searching])

  const back = () => {
    navigate("/blog/")
  }

  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list?.length
      const isMore = currentLength < filterData?.length
      const nextResults = isMore
        ? filterData?.slice(currentLength, currentLength + length)
        : []
      setList([...list, ...nextResults])
      setLoadMore(false)
    }
  }, [loadMore, hasMore])

  useEffect(() => {
    const isMore = list.length < filterData?.length
    setHasMore(isMore)
  }, [list, filterData])

  const handleLoadMore = () => {
    setLoadMore(true)
  }

  return (
    <MainLayout bgChanged={false}>
      <section>
        <Container>
          <React.Fragment>
            <div className={styles.topSection}>
              <div className={styles.back} onClick={back}>
                <StaticImage
                  placeholder="blurred"
                  decoding="async"
                  loading="lazy"
                  alt="back"
                  src={"../images/new-blog/back.svg"}
                />{" "}
                <span>BACK</span>
              </div>
              <div className={styles.heading}>INVOBLOG</div>
            </div>
            <Row>
              {
                // loading ? (
                //   <p className="w-100  d-flex justify-content-center">
                //     <Spinner
                //       variant="primary"
                //       as="span"
                //       animation="border"
                //       size="lg"
                //       role="status"
                //       aria-hidden="true"
                //     />
                //   </p>
                // ) :
                list?.length > 0 ? (
                  list?.map((post, i) => (
                    <Col
                      md={6}
                      lg={4}
                      xl={4}
                      key={i}
                      style={{ padding: 10, display: "flex" }}
                    >
                      <InvoBlogs post={post} />
                    </Col>
                  ))
                ) : (
                  <Col className="text-center">No Data</Col>
                )
              }
            </Row>
          </React.Fragment>
          {hasMore && (
            <Row className="mt-5">
              <Col className="d-flex justify-content-center">
                <button className="btn_white2_border" onClick={handleLoadMore}>
                  Load more
                </button>
              </Col>
            </Row>
          )}
        </Container>
      </section>
      <BlogModal
        categories={pageContext?.allCategories}
        setFilterCatagory={setFilterCatagory}
        setSearching={setSearching}
        setTopicSearch={setTopicSearch}
      />
      <Creative />
    </MainLayout>
  )
}

export default BlogTemplate

export const Head = () => {
  return (
    <SEORevamp
      title="Read Tech Expert Opinions | InvoZone Blog"
      description="Read the InvoZone blog to catch up on interesting insights and expert opinions related to software development, the Internet and technology."
      image="https://invozone-backend.s3.amazonaws.com/Blogs_29bc5a670c.webp"
    />
  )
}
